import React, { useState, useEffect } from 'react';
import moment from "moment";

/**
 * Component to render duration timer with minutes:seconds (0:00)
 * @returns a timer duration in minutes and seconds(01:59)
 */
// const CallTimer = () => {
//   const [totalSeconds, setTotalSeconds] = useState(0);
//   // const [seconds, setSeconds] = useState(1);
//   // const [minutes, setMinutes] = useState(0);



//   useEffect(() => {
//     const timer = setInterval(() => {
//       setTotalSeconds((prevTotalSeconds) => prevTotalSeconds + 1);
//     }, 1000);

//     return () => clearInterval(timer);
//   }, []);

//   // const formatTime = (minutes, seconds) => {
//   //   const displayMinutes = Math.floor(minutes % 60);
//   //   const displaySeconds = seconds % 60;
//   //   return `${displayMinutes.toString().padStart(2, '0')}:${displaySeconds.toString().padStart(2, '0')}`;
//   // };
//   const formatTime = (time) => {
//     return time < 10 ? `0${time}` : time;
//   };

//   const minutes = Math.floor(totalSeconds / 60);
//   const seconds = totalSeconds % 60;

//   // useEffect(() => {
//   //   const interval = setInterval(() => {
//   //     setSeconds((prevSeconds) => {
//   //       if (prevSeconds === 59) {
//   //         setMinutes((prevMinutes) => prevMinutes + 1);
//   //         return 0;
//   //       }
//   //       return prevSeconds + 1;
//   //     });
//   //   }, 1000);

//   //   return () => clearInterval(interval);
//   // }, []);

//   // const formatTime = (time) => {
//   //   return time < 10 ? `0${time}` : time;
//   // };
//   return (
//     <div>
//       {/* <div>{formatTime(Math.floor(totalSeconds / 60), totalSeconds)}</div> */}
//         {formatTime(minutes)}:{formatTime(seconds)}
//     </div>
//   );
// };

// export default CallTimer;

let timer;
const CallTimer = ({setAutoEndCallTime}) => {
  const [elapsedTime, setElapsedTime] = useState("00:00");
  const storedInitialDateTime = localStorage.getItem('initialDateTime');
  const initialDateTime = storedInitialDateTime ? moment(storedInitialDateTime) : moment();

  function updateTimerDisplay() {
    const currentTime = moment();
    const duration = moment.duration(currentTime.diff(initialDateTime));
    const formattedTime = moment.utc(duration.asMilliseconds()).format('mm:ss');
    setElapsedTime(formattedTime); // Replace with your actual display mechanism
    if (typeof setAutoEndCallTime =='function') {
      setAutoEndCallTime(formattedTime)
    }
  }

  useEffect(() => {
    timer = setInterval(() => {
      updateTimerDisplay();
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div>
        {elapsedTime}
    </div>
  );
};

export default CallTimer;
